.about-us-wrap {
    ul {
        padding-left: 15px; }

    p,li {
        margin-bottom: 1.5rem;
        font-size: 14px;
        font-weight: 400;
        color: #495057; } }



.accreditation {
    background: #F5F7FA;

    img {
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0); } } }

.about-us {
    background: #F5F7FA;

    .about-img {
        //position: absolute
        //z-index: 1
        //bottom: -30px
        //width: 70%
        //right: 10px
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            filter: brightness(0.5); }
        @include mq(max, 768px) {
            display: none; } } }

