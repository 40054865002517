@mixin mq($arg...) {
    @if type_of(nth($arg, 1)) == string {
        @media (#{nth($arg, 1)}-width: nth($arg, 2)) {
            @content; } }
    @else {
        @media (min-width: nth($arg, 1)) and (max-width: nth($arg, 2)) {
            @content; } } }

@mixin mqh($arg...) {
    @if type_of(nth($arg, 1)) == string {
        @media (#{nth($arg, 1)}-height: nth($arg, 2)) {
            @content; } }
    @else {
        @media (min-height: nth($arg, 1)) and (max-height: nth($arg, 2)) {
            @content; } } }

// colors
$red: #CC3530;
$dark: #110729;
$grey: #4B5981;
$black: #000;
$light-grey: #9D9D9D;
$light-black: #495057;
$dark-grey: #232323;
$darkgrey: #323334;

// variables
$primary: $red;
$secondary: $grey;
$ternary: $light-grey;
$success: #05c908;
$muted: #777777;

$header-height: 76px;
$footer-height: 130px;
