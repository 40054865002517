.modal-backdrop.show {
    opacity: 0.9; }

.giveaway,
.alert-modal {
    @include mq(max, 768px) {
        .img-fluid {
            width: 75%;
            margin: 0 auto; } }


    .border-radius {
        border-top-right-radius: 0.75em;
        border-bottom-right-radius: 0.75em;

        @include mq(max, 768px) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0.75em;
            border-top-left-radius: 0.75em; } }

    .modal-content {
        border-radius: 0.75em; }

    .modal-body {
        @include mq(max, 768px) {
            .row {
                flex-direction: column-reverse; } } }

    .info {
        background: #4CA15A;
        color: #fff;
        border-top-left-radius: 0.75em;
        border-bottom-left-radius: 0.75em;

        @include mq(max, 768px) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0.75em;
            border-bottom-left-radius: 0.75em; } }

    .counter {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq(max, 768px) {
            text-align: center; }


        h2 {
            text-transform: capitalize;
            text-align: left;

            @include mq(max, 768px) {
                text-align: center; } }

        p {
            @include mq(max, 768px) {
                font-size: 0.9em; } } }

    .modal-content {
        background: transparent; }

    .modal-footer {
        border-top: none;
        position: absolute;
        right: -25px;
        top: -25px; }

    .modal-close {
        background: #ffffff;
        color: #000000;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        box-shadow: (-1px) 1px 6px #a4a4b5;

        i {
            font-size: 1.5em; } }

    .btn-redirect {
        background: #DC3C3B;
        border-color: #DC3C3B;
        color: #fff;
        font-size: 1.1em;
        padding: 0.5em 1.5em;

        &:hover {
            color: #fff;
            background: #DC3C3B; } }

    .winner-img {
        border: 8px solid #fff;
        border-radius: 50%;
        max-width: 180px;
        margin: 1.5em; } }

.alert-modal {
    @include mq(max, 768px) {
        .modal-body {
            .row {
                flex-direction: column; }

            img {
                margin-bottom: 2em; } } } }
