.lottie-parent {
    height: 150px;
}

.buyers-steps .step-01 {
    animation: focus 7s linear;
}

.buyers-steps .step-02 {
    animation: focus 6s linear;
    animation-delay: 7s;
}

.buyers-steps .step-03 {
    animation: focus 7.5s linear;
    animation-delay: 13.5s;
}

.buyers-steps .step-04 {
    animation: focus 5s linear;
    animation-delay: 13.5s;
}

.buyers-steps .step-05 {
    animation: focus 5s linear;
    animation-delay: 20s;
}

.buyers-steps .step-06 {
    animation: focus 5s linear;
    animation-delay: 25s;
}

.step-01-wrap:before, .step-02-wrap:before, .step-03-wrap:before, .step-04-wrap:before, .step-05-wrap:before, .step-06-wrap:before {
    animation: progress 7s linear;
    width: 0;
    opacity: 0;
}

.step-02-wrap:before {
    animation-delay: 7s;
}

.step-03-wrap:before {
    animation-delay: 14s;
}

.step-04-wrap:before {
    animation-delay: 13.5s;
}

.step-05-wrap:before {
    animation-delay: 20s;
}

.step-06-wrap:before {
    animation-delay: 25s;
}

$time : 5s;

.get-started-steps{
    .step-01-wrap:before, 
    .step-02-wrap:before, 
    .step-03-wrap:before,
    .step-04-wrap:before,
    .step-05-wrap:before{
        animation: none;
    }

    .step-01,
    .step-02,
    .step-03,
    .step-04,
    .step-05 {
        animation: none;
    }
    
    .card,
    .count {
        opacity: 1 !important;
        margin-bottom: 0
    }
}

@keyframes focus {
    0% {
        opacity: .2;
    }

    20% {
        opacity: 1;
        transform: scale(1);
    }

    80% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: .2;
        transform: scale(0.85);
    }
}

@keyframes progress {
    0% {
        width: 0;
    }

    90% {
        width: calc(100% - 40px);
        opacity: 1;
    }

    100% {
        opacity: 0;
        width: calc(100% - 40px);
    }
}


.card {
    animation: showall 0s linear;
}

@keyframes showall {
    0% {
        opacity: .95;
    }

    10% {
        opacity: .95;
    }

    90% {
        opacity: .95;
    }

    100% {
        opacity: 1;
        border: 2px solid #f00;
    }
}

.buyers-steps .card, .buyers-steps .count {
    opacity: .2;
    transform: scale(0.95);
}

.buyers-steps .card {
    transform: scale(0.85);
}

.buyers-steps .block {
    position: relative;
    margin: 2em 0;
}

.buyers-steps .block:before {
    content: "";
    position: absolute;
    left: calc(50% + 20px);
    height: 5px;
    width: 0;
    top: 5%;
    z-index: 1;
    font-weight: bolder;
    background: #fff;
    z-index: 99;
    opacity: 1;
}

.buyers-steps .block:after {
    content: "";
    position: absolute;
    left: calc(50% + 20px);
    height: 5px;
    width: calc(100% - 40px);
    top: 5%;
    z-index: 1;
    letter-spacing: 7px;
    background: #fff;
    opacity: 0.4;
}

@media (min-width: 768px) {
    .buyers-steps .block:last-child:before, .buyers-steps .block:last-child:after {
        display: none;
    }
}
