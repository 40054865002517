.contact-us {
    .container {
        display: flex;
        align-content: center;
        position: relative;

        @include mq(max, 991px) {
            display: block; } }

    .contact-form {
        width: 100%;
        padding: 5em 6rem;
        margin-left: 16%;
        padding-left: 22%;
        box-shadow: 0px 3px 12px rgba(51, 52, 87, 0.1607843137);

        @include mq("max", 768px) {
            padding: 1rem;
            margin-left: 0;
            padding-left: 8px; } }

    .contact-info .card {
        width: 400px;
        margin: 0 auto;
        position: absolute;
        top: 25%;
        left: 0;
        background: $primary;
        color: #fff;
        font-weight: 300;

        .header {
            font-weight: 400;
            font-size: 1.6rem;
            margin-bottom: 1rem;
            position: relative;

            &::after {
                content: "";
                width: 75px;
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                background-color: #fff; } }

        @include mq(max, 991px) {
            position: unset;
            width: auto; } } }

.contact-form .form-control::-webkit-input-placeholder {
    color: #C9C9C9 !important;
    font-size: 12px; }

.contact-form .form-control:-ms-input-placeholder {
    color: #C9C9C9 !important;
    font-size: 12px; }

.contact-form .form-control::placeholder {
    color: #C9C9C9 !important;
    font-size: 12px; }
