.services {
    .heading {
        background: $primary;
        color: #fff;
        padding: 8px 20px;
        letter-spacing: 2px; }

    img {
        filter: brightness(65%); }

    ul {
        padding-left: 0px;
        font-weight: 500;
        color: #333457;
        font-size: 14px;
        list-style: none;

        li:before {
            content: "• ";
            color: #333457;
            margin-right: 5px;
            font-size: 16px; } }

    .form-wrapper {
        background: #fff;
        box-shadow: 0px 3px 12px rgba(51, 52, 87, 0.1607843137);
        margin-top: -15rem;
        background: #fff;
        position: relative;
        padding: 3rem 13rem;

        @include mq("max", 1200px) {
            padding: 2rem; } } }

.enquiries {
    max-height: 450px;
    height: 450px;
    background: linear-gradient(14deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6769082633) 0%), url(../images/services/18936.png);
    background-size: cover;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;

    .curve {
        height: 100px;
        margin-left: -5%;
        margin-right: -5%;
        overflow: hidden;
        position: relative;
        width: 100%;
        left: 5%;
        right: 0;

        &::after {
            content: "";
            border-bottom-left-radius: 54% 35%;
            border-bottom-right-radius: 55% 35%;
            height: 300px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: white;
            width: 100%;

            @include mq(max, 768px) {
                border-bottom-left-radius: 50% 15%;
                border-bottom-right-radius: 50% 15%; } } } }
