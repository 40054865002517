.covid-banner {
    color: #fff;

    .covid-banner-img {
        @include mq(max, 768px) {
            display: none; } }

    .title {
        padding: 0.5em 1.5em;
        background: #2B9133;
        width: max-content;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        @include mq(max, 768px) {
            width: 100%; } }

    .payment-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #4CA753;
        border-radius: 15px;
        border-top-left-radius: 0;
        padding: 1.5em 0;
        z-index: 1;
        box-shadow: 0px 13px 20px 0px #272121;

        @include mq(max, 768px) {
            flex-direction: column;
            align-items: baseline;
            border-top-right-radius: 0; }


        .block {
            padding: 0 3em 0 2em;
            border-right: 1px solid rgba(255, 255, 255, 0.3);

            @include mq(max, 768px) {
                border-right: none;
                margin: 0.5em 0;
                width: 100%; }


            &:last-child {
                border-right: none; } } } }

.covid-info {
    color: #fff;

    .block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            padding-bottom: 1em;
            margin: 0 0 1.5em;
            width: 100%;
            border-bottom: 2px dashed #989898; } }

    .btn {
        background: #4CA753;
        color: #ffffff;
        border-radius: 5px;
        font-size: 1.25em;
        display: flex;
        align-items: center;
        margin: 1em 1em 1em 0;

        &:hover {
            color: #fff; }

        img {
            width: 30px; }

        div {
            line-height: 20px; }

        span {
            display: inline-block;
            text-align: left;
            text-transform: none; } }

    .cta {
        @include mq(max, 768px) {
            flex-direction: column;
            align-items: center; } } }

.covid-form {
    color: #000;
    border-radius: 15px;

    .card-body {
        border-radius: 15px; }

    .form-group {
        margin-bottom: 0.5em; }

    .form-control {
        border: 1px solid #ddd;
        padding: 1em;
        border-radius: 3px;
        height: 40px; }

    button {
        border-radius: 5px; } }

.covid-faqs {
    color: #000;

    strong {
        font-weight: 500;
        color: #000; }

    p {
        font-size: 1.25em;
        color: #646464; } }
