/* Please ❤ this if you like it! */

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");

/* #Navigation
================================================== */

.start-header {
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0px 4px 13px 0 rgba(138, 155, 165, 0.15);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.sub-nav a {
    text-decoration: none;
}

.navigation-wrap {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    background: #fff;

    .navbar {
        padding-bottom: 1em;
    }

    .navbar-brand img {
        height: 46px;
        width: auto;
        display: block;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    .navbar-toggler {
        float: right;
        border: none;
        padding-right: 0;
    }

    .navbar-toggler:active,
    .navbar-toggler:focus {
        outline: none;
    }

    .navbar-light .navbar-toggler-icon {
        width: 24px;
        height: 17px;
        background-image: none;
        position: relative;
        border-bottom: 1px solid #000;
        transition: all 300ms linear;
    }

    .navbar-light .navbar-toggler-icon:after,
    .navbar-light .navbar-toggler-icon:before {
        width: 24px;
        position: absolute;
        height: 1px;
        background-color: #000;
        top: 0;
        left: 0;
        content: '';
        z-index: 2;
        transition: all 300ms linear;
    }

    .navbar-light .navbar-toggler-icon:after {
        top: 8px;
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
        transform: rotate(45deg);
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
        transform: translateY(8px) rotate(-45deg);
    }

    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
        border-color: transparent;
    }

    .nav-link {
        color: #212121 !important;
        font-weight: 500;
        transition: all 200ms linear;
    }

    .nav-item:hover .nav-link {
        color: $primary !important;
    }

    .nav-item.active .nav-link {
        color: $primary !important;
    }

    .nav-link {
        position: relative;
        padding: 5px 0 !important;
        display: inline-block;
        text-transform: uppercase;
        outline: none;
        box-shadow: none;
    }

    .nav-item:after {
        position: absolute;
        top: -23px;
        left: 50%;
        width: 30px;
        height: 1px;
        content: "";
        background-color: $primary;
        opacity: 0;
        transition: all 200ms linear;
        transform: translateX(-50%);
    }

    .nav-item:before {
        position: absolute;
        top: -34px;
        left: 50%;
        font-family: "Font Awesome 5 Free";
        content: "\f0d7";
        font-weight: 900;
        color: $primary;
        opacity: 0;
        transition: all 200ms linear;
        transform: translateX(-50%);
        font-size: 18px;
    }

    .nav-item:hover:after,
    .nav-item.active:after,
    .nav-item:hover:before,
    .nav-item.active:before {
        opacity: 1;
    }

    .nav-item {
        position: relative;
        transition: all 200ms linear;
    }

    .nav-item .dropdown-menu {
        transform: translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        display: block;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
    }

    .nav-item.show .dropdown-menu {
        opacity: 1;
        visibility: visible;
        max-height: 999px;
        transform: translate3d(0, 0px, 0);
        top: 54px;
        border-radius: 0;
    }

    .dropdown-menu {
        // padding: 10px !important;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1px;
        color: #212121;
        background-color: #fff;
        border: none;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        transition: all 200ms linear;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-item {
        padding: 10px 15px;
        color: #212121;
        border-radius: 2px;
        transition: all 200ms linear;
    }

    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item.active {
        color: #fff;
        background: $primary;
    }
}

/* #Media
================================================== */

@media (max-width: 767px) {
    .navigation-wrap .nav-item:hover .nav-link {
        color: #212121 !important;
    }

    .h2, h2 {
        font-size: 1.6rem;
    }

    .h3, h3 {
        font-size: 1.2rem;
    }

    .sub-nav {
        display: none;
    }

    .nav-item:after {
        display: none;
    }

    .nav-item::before {
        position: absolute;
        display: block;
        top: 15px;
        left: 0;
        width: 11px;
        height: 1px;
        content: "";
        border: none;
        background-color: #000;
        vertical-align: 0;
    }

    .dropdown-toggle::after {
        position: absolute;
        display: block;
        top: 10px;
        left: -23px;
        width: 1px;
        height: 11px;
        content: "";
        border: none;
        background-color: #000;
        vertical-align: 0;
        transition: all 200ms linear;
    }

    .dropdown-toggle[aria-expanded="true"]::after {
        transform: rotate(90deg);
        opacity: 0;
    }

    .dropdown-menu {
        padding: 0 !important;
        background-color: transparent;
        box-shadow: none;
        transition: all 200ms linear;
    }

    .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        box-shadow: none;
    }

    body.dark .nav-item::before {
        background-color: #fff;
    }

    body.dark .dropdown-toggle::after {
        background-color: #fff;
    }

    body.dark .dropdown-menu {
        background-color: transparent;
        box-shadow: none;
    }

    .navigation-wrap {
        .nav-item:hover:after,
        .nav-item.active:after,
        .nav-item:hover:before,
        .nav-item.active:before {
            opacity: 0;
        }

        .dropdown-item {
            font-size: 14px;
        }

        .nav-link {
            font-size: 16px;
            padding-bottom: 15px !important;
            display: block;
        }
    }
}

// Heighlighter
.navigation-wrap .nav-link.heighlighter {
    color: #f00 !important;
}
