.map-wrap {
    text-align: center;
    height: 100%;
    background: #eff1f4;
    .map-title {
        background: $primary;
        color: #fff;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: 2px;
        padding: 5px; }
    .map-content {
        padding: 20px 40px;
        background: #EFF1F4;
        color: #333457;
        font-size: 13px;
        font-weight: 600; }
    iframe {
        width: 100%; } }

.gm-style .place-card {
    display: none; }
