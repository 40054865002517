// new

$maxSizeBreakpoint: 600px;
$padding: 40px;
$leafBackgroundColor: #f7f7f7;
$leafTextColor: #de4848;
$leafFontsize: 45px;
$leafFontFamily: "Impact", sans serif;
$leafHeight: 40px;
$leafWidth2Digits: 70px;
$leafWidth3Digits: 68px;
$leafBorderRadius: 6px;
$leafShadowOpacity: 0.8;
$gapSize: 4px;
$foldSize: 2px;
$foldColor: #dad8d8;
$perspective: 300px;
$labelFontSize: 14px;
$labelFontFamily: "Geneva", sans serif;
$labelFontWeight: 400;
$labelTextColor: #000;
$labelMarginTop: 8px;

$duration: 0.3s;

// html {
//     box-sizing: border-box;
//     background-color: #2d3748;
// }

/// Flipclock Looks
.flipclock {
    display: flex;

    // justify-content: center;
    // padding: $padding;
    box-sizing: border-box;
}

.flipclock * {
    box-sizing: inherit;
}

.leaf {
    display: flex;
    flex-direction: column;
    margin: 0 $gapSize;
    perspective: $perspective;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
    border-radius: 10px;
}

.leaf::after {
    content: attr(data-label);
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: $labelMarginTop;
    transform: translateX(-50%);
    color: $labelTextColor;
    font-size: $labelFontSize;
    font-weight: $labelFontWeight;
    text-transform: uppercase;
    opacity: 0.5;
}

.top,
.bottom,
.leaf-front,
.leaf-back {
    position: relative;
    display: block;
    height: $leafHeight;
    width: $leafWidth2Digits;
    background-color: $leafBackgroundColor;
    color: $leafTextColor;
    overflow: hidden;
    border-color: $foldColor;

    span {
        position: absolute;
        left: 50%;
        width: 100%;
        height: $leafHeight * 2;
        text-align: center;
        font-family: $leafFontFamily;
        font-size: $leafFontsize;
        line-height: $leafHeight * 2;
        transform: translateX(-50%);
    }
}

.bottom {
    background-color: #fff;
}

.leaf._3-digits {
    .top,
    .bottom,
    .leaf-front,
    .leaf-back {
        width: $leafWidth3Digits;
    }
}

.top,
.leaf-front {
    border-top-left-radius: $leafBorderRadius;
    border-top-right-radius: $leafBorderRadius;
    margin-bottom: 0;
    border-bottom-style: solid;
    border-bottom-width: 0;

    span {
        top: 0;
    }
}

.leaf-front {
    position: absolute;
    z-index: 10;
}

.bottom,
.leaf-back {
    border-bottom-left-radius: $leafBorderRadius;
    border-bottom-right-radius: $leafBorderRadius;
    border-top-style: solid;
    border-top-width: $foldSize / 2;

    span {
        bottom: 0;
    }
}

.leaf-back {
    position: absolute;
    top: $leafHeight;
    z-index: 10;
}

@media screen and (max-width: $maxSizeBreakpoint) {
    .flipclock {
        padding: $padding * 100 / $maxSizeBreakpoint * 1vw;
    }

    .leaf {
        margin: 0 ($gapSize * 100 / $maxSizeBreakpoint * 1vw);
    }

    .leaf::after {
        margin-top: $labelMarginTop * 100 / $maxSizeBreakpoint * 1vw;
        font-size: $labelFontSize * 100 / $maxSizeBreakpoint * 1vw;
    }

    .top,
    .bottom,
    .leaf-front,
    .leaf-back {
        height: $leafHeight * 100 / $maxSizeBreakpoint * 1vw;
        width: $leafWidth2Digits * 100 / $maxSizeBreakpoint * 1vw;

        span {
            height: $leafHeight * 100 / $maxSizeBreakpoint * 1vw * 2;
            font-size: $leafFontsize * 100 / $maxSizeBreakpoint * 1vw;
            line-height: $leafHeight * 100 / $maxSizeBreakpoint * 1vw * 2;
        }
    }

    .leaf._3-digits {
        .top,
        .bottom,
        .leaf-front,
        .leaf-back {
            width: $leafWidth3Digits * 100 / $maxSizeBreakpoint * 1vw;
        }
    }

    .top,
    .leaf-front {
        border-top-left-radius: $leafBorderRadius * 100 / $maxSizeBreakpoint * 1vw;
        border-top-right-radius: $leafBorderRadius * 100 / $maxSizeBreakpoint * 1vw;

        // margin-bottom: ((($foldSize * 100) / $maxSizeBreakpoint) * 1vw / 2);
   
    }

    .bottom,
    .leaf-back {
        border-bottom-left-radius: $leafBorderRadius * 100 / $maxSizeBreakpoint * 1vw;
        border-bottom-right-radius: $leafBorderRadius * 100 / $maxSizeBreakpoint * 1vw;

        //border-top-width: ((($foldSize * 100) / $maxSizeBreakpoint) * 1vw / 2);
   
    }

    .leaf-back {
        top: $leafHeight * 100 / $maxSizeBreakpoint * 1vw;
    }
}

/// Flipclock Animation
.leaf-front {
    transform-origin: bottom center;
    transform: rotateX(0deg);
    transform-style: preserve-3d;
    transition-delay: $duration;
}

.leaf-back {
    transform-origin: top center;
    transform: rotateX(90deg);
    transform-style: preserve-3d;
    transition-delay: 0s;
}

.bottom::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, $leafShadowOpacity) 0%, rgba(0, 0, 0, 0) 100%);
    filter: blur(10px);
}

.flip {
    .leaf-front {
        transform: rotateX(-90deg);
        transition: transform $duration ease-in, background-color $duration ease-in, color $duration ease-in;
        transition-delay: 0s;
        color: darken($leafTextColor, 50%);
        background-color: darken($leafBackgroundColor, 50%);
    }

    .leaf-back {
        transform: rotateX(0deg);
        transition: transform $duration ease-in, background-color $duration ease-in, color $duration ease-in;
        transition-delay: $duration;
    }

    .bottom::before {
        transition: height $duration ease-in-out;
        transition-delay: $duration / 2;
        height: 100%;
    }
}

@media (max-width: 768px) {
    .giveaway .modal-footer {
        right: -16px;
    }

    .giveaway .btn-redirect {
        width: 75% !important;
        margin: auto;
    }

    .flipclock {
        justify-content: center;
    }
}
