.loader {
    position: absolute;
    top: 50%;
    left: 50%;
}

.child {
    position: absolute;
    right: 0;
    animation: bounce 2s linear infinite;
    transform-origin: 15px 35px;
}

.parent:after {
    content: "";
    height: 2px;
    width: 30px;
    position: absolute;
    bottom: -29px;
    right: 0;
    border-radius: 50%;
    box-shadow: 0px 12px 2px 1px #06060640;
    animation: animateone 2s linear infinite;
}

.child:before {
    content: "";
    background: #34B2E4;
    height: 35px;
    width: 30px;
    border-radius: 24px 24px 0 0;
    float: left;
    bottom: 0;
    position: relative;
}

.child:after {
    width: 30px;
    height: 35px;
    background: #264D6E;
    border-radius: 0 0 24px 24px;
    content: " ";
    position: absolute;
    left: 0;
    top: 37px;
}

@keyframes animateone {
    0% {
        transform: scale(1);
    }

    15% {
        transform: scale(0.4);
    }

    30% {
        transform: scale(0.4);
    }

    45% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.4);
    }

    75% {
        transform: scale(0.4);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    0% {
        bottom: 0;
        transform: rotate(0);
    }

    15% {
        bottom: 30px;
        transform: rotate(0);
    }

    30% {
        bottom: 30px;
        transform: rotate(-180deg);
    }

    45% {
        bottom: 0;
        transform: rotate(-180deg);
    }

    60% {
        bottom: 30px;
        transform: rotate(-180deg);
    }

    75% {
        bottom: 30px;
        transform: rotate(-360deg);
    }

    100% {
        bottom: 0;
        transform: rotate(-360deg);
    }
}
