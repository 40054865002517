html {
    scroll-behavior: smooth; }

body {
    background: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: $black; }

.root {
    height: 100vh; }

@mixin ft($size: 28) {
    @for $i from 10 through $size {
        .ft-#{$i} {
            font-size: #{$i}px !important; } } }

@include ft;

a:hover {
    text-decoration: none; }

.body-loader {
    overflow: hidden; }

.text-grey {
    color: $light-grey; }

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;

    // overflow: auto

    header {
        height: $header-height;
        background: #fff; }

    .content-area {
        flex: 1;
        padding-top: $header-height;

        // +mq("max", 768px)
 }        //     padding-top: 215px

    .corona-counter {
        @include mq("max", 768px) {
            .container {
                flex-direction: column; }

            .d-flex {
                flex-direction: column;
                margin-top: 1em;
                align-items: flex-start !important; }

            .pipe {
                display: none; } } }

    footer {
        // height: $footer-height
        padding: 5em 0 0;
        background: #EFF1F4;
        position: relative;

        @include mq(max, 768px) {
            margin-top: 4rem; }


        .curve {
            width: 250px;
            height: 35px;
            background: #eff1f4;
            overflow: visible;
            position: absolute;
            top: -35px;

            &::after {
                content: "";
                position: absolute;
                display: block;
                right: -50px;
                top: 0;
                width: 0px;
                height: 0px;
                border-bottom: solid 50px #eff1f4;
                border-right: solid 50px transparent; } }

        form {
            .form-control,
            .input-group-text {
                background: $primary;
                color: #fff;
                border-right: 0;
                font-size: 14px; } }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding: 5px 0;

                a, span {
                    color: #9D9D9D; }

                &:before {
                    font-family: "Font Awesome 5 Free";
                    content: "";
                    font-weight: 900;
                    color: #9D9D9D;
                    padding-right: 5px; } } }

        .links {
            display: flex;

            @include mq(max, 768px) {
                justify-content: space-between; } }

        @include mq(max, 768px) {
            .container {
                flex-direction: column; }

            .desc {
                text-align: center;
                width: 100% !important; } } }

    p {
        font-size: 1em; }

    .sub-heading {
        position: relative;
        margin-bottom: 25px;
        font-size: 1.2rem;

        &:after {
            content: "";
            font-size: 1.5em;
            position: absolute;
            bottom: -6px;
            height: 3px;
            width: 70px;
            left: 2px;
            background: $primary; } }

    .social-media {
        @include mq(max, 768px) {
            text-align: center; } } }

// Text styling
.text-primary {
    color: $primary !important; }

.text-dark {
    color: $light-black !important; }

.text-gray {
    color: $grey !important; }

.text-light {
    color: $light-grey !important; }

.text-black {
    color: $black !important; }

//font weight
.font-weight-500 {
    font-weight: 500 !important; }

.font-weight-600 {
    font-weight: 600 !important; }

.font-weight-medium {
    font-weight: 400 !important; }

// Background
.bg-primary {
    background: $primary !important; }

.bg-darkgrey {
    background: $darkgrey !important; }

// Button
.btn {
    font-size: 12px;
    text-transform: uppercase; }

.btn-primary {
    background: $primary !important;
    color: #fff;
    border-color: $primary !important; }

.btn-outline-primary {
    border: 1px solid $primary;
    color: $primary;
    background: #fff; }

.btn-outline-secondary {
    border: 1px solid $dark;
    color: $dark;
    background: #fff; }

.btn-outline-ternary {
    border: 1px solid #fff;
    color: #fff !important; }

// Icon width

.i-4 {
    width: 40px !important; }

.i-5 {
    width: 50px !important; }

.i-6 {
    width: 60px !important; }

.i-7 {
    width: 70px !important; }

.i-8 {
    width: 80px !important; }

.i-10 {
    width: 100px !important; }

.slick-slide {
    margin: 0 10px; }

.slick-list {
    margin: 0 -10px; }

.cm-header {
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 2px;

    @include mq(max, 768px) {
        font-size: 22px; } }

.cm-content {
    color: $light-black;
    font-family: "Rubik", sans-serif;
    line-height: 20px;
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 14px !important; }

.cm-sub-text {
    color: $grey;
    font-weight: 400;
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 18px;

    @include mq(max, 768px) {
        font-size: 1 rem; } }

footer input::-webkit-input-placeholder {
    color: #fff !important;
    font-weight: 300; }

footer input:-ms-input-placeholder {
    color: #fff !important;
    font-weight: 300; }

footer input::placeholder {
    color: #fff !important;
    font-weight: 300; }

.page-heading {
    color: #110729;
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 2px;
    margin-bottom: 1.2rem; }

.page-sub-text {
    color: #ED1C24;
    font-size: 1.2rem;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;

    &::after {
        content: "";
        width: 50px;
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 2px;
        background-color: #01C0CF; } }

.form {
    .form-control {
        border: 0;
        border-bottom: 2px solid #E3EEFF;
        padding-left: 0;
        color: #5d5d5d;
        font-weight: 300;
        font-size: 14px;
        border-radius: 0;

        &:focus {
            box-shadow: unset; } }

    .error-text {
        color: $primary;
        margin: 4px 2px;
        height: 18px; } }

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff; }

.spinner-cust {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinit;

    &::before {
        content: ""; } }

.mobile-hide {
    @include mq("max", 768px) {
        display: none; } }

.mobile-show {
    display: none;

    @include mq("max", 768px) {
        display: inline-block; } }

.floating-label {
    position: relative;

    .form-control:focus ~ .form-label,
    .form-control:not(:placeholder-shown) ~ .form-label {
        top: -12px;
        font-size: 11px; }

    .form-label {
        position: absolute;
        pointer-events: none;
        top: 8px;
        transition: 0.2s ease all;
        font-size: 14px;
        font-weight: 300;
        color: #5d5d5d; } }

.page-loader {
    background: #ffffff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1001; }

.pt-7 {
    padding-top: 7.2rem; }

.mobile-div {
    @include mq("max", 768px) {
        text-align: center; } }

// Covid 19 CT scan page
.covid-ctscan {
    padding: 1em 0 !important; }

// faq
.accordion {
    > .card:not(:last-of-type) {
        border-bottom: 1px solid #ddd;
        margin-bottom: 1em; }

    .btn:hover,
    .btn:focus {
        box-shadow: none; } }
