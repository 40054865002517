.landing-section {
    height: 600px;
    padding: 0;

    @include mq(max, 768px) {
        height: auto;
        padding: 0 !important;

        h1 {
            font-size: 1.3rem; } }


    .carousel-inner,
    .carousel {
        height: 100%; }

    .carousel-item {
        height: 100%;
        background-size: cover;
        min-height: 350px;
        background: no-repeat center center scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        &:nth-child(1) {
            background-image: url("../images/banner/banner1.jpg"); }

        &:nth-child(2) {
            background-image: url("../images/banner/goggle_review_banner_image.jpg"); }

        &:nth-child(3) {
            background-image: url("../images/banner/banner2.jpg"); } }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #080d15;
        opacity: 0.7; }

    .carousel-caption {
        top: 35%;
        bottom: 0;
        text-align: left;
        left: 25%;

        @include mq(max, 1200px) {
            left: 9%;
            right: 9%; }


        @include mq(max, 768px) {
            text-align: center;
            width: auto;
            top: 49%;
            transform: translateY(-65%); } }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-image: none; }

    .carousel-control-next {
        right: 9%;

        @include mq(max, 1200px) {
            right: 11px;
            width: 5%; } }

    .carousel-control-prev {
        left: 7%;

        @include mq(max, 1200px) {
            left: 11px;
            width: 5%; } }

    .carousel-control-next,
    .carousel-control-prev {
        z-index: 10;
        opacity: 1; }

    .carousel-control-next-icon:after,
    .carousel-control-prev-icon:after {
        font-size: 13px;
        color: #ffffff;
        letter-spacing: 4px;
        font-weight: 400;
        white-space: pre; }

    .carousel-control-next-icon:after {
        content: url(../images/banner/Union1.svg) " Next";

        @include mq(max, 992px) {
            content: url(../images/banner/Union1.svg); } }

    .carousel-control-prev-icon:after {
        content: "Prev " url(../images/banner/Union.svg);

        @include mq(max, 992px) {
            content: url(../images/banner/Union.svg); } } }

.about-section {
    position: relative;

    img {
        max-width: 650px;
        margin: 0 auto;
        width: 100%;
        padding-top: 5em;
        -webkit-filter: brightness(50%);
        filter: brightness(50%); }

    .info-card {
        width: 400px;
        margin: 0 auto;
        position: absolute;
        top: 15%;
        left: 20%;
        background: #FAFAFA;

        .cm-sub-text {
            &::after {
                content: "";
                width: 50px;
                position: absolute;
                bottom: 0px;
                left: 0;
                height: 2px;
                background-color: #01C0CF; } }

        @include mq(max, 768px) {
            position: unset;
            width: auto; } }

    @include mq(max, 768px) {
        .about-img {
            display: none; } } }

.services-section {
    background: #E5E5E5;
    position: relative;
    overflow: hidden;
    counter-reset: section;

    // &:before
    //     content: ""
    //     background: white
    //     position: absolute
    //     content: ""
    //     left: 0
    //     right: 0
    //     bottom: -25px
    //     height: 50px
    //     border-radius: 50% 50% 0 0

    .card {
        border: 2px dashed #707070;
        margin-bototm: 1em;
        height: 100%;

        img {
            margin: 0 auto; }

        &:after {
            position: absolute;
            right: 12px;
            top: 0;
            counter-increment: section;
            content: "0" counter(section);
            font-weight: bold;
            color: #EFF1F4;
            font-size: 3rem; }

        &:hover {
            border: 2px dashed $primary;

            &:after {
                color: rgba(#ED1C24, 0.25); } }

        @include mq(max, 768px) {
            flex: unset;
            margin: 1em; } } }

.solutions-section {
    padding: 0 !important;
    background: #E5E5E5;
    overflow: hidden;

    // width: 0
    // height: 0
    // border-top: 100px solid red
    // border-right: 100px solid transparent

    // .curves
    //     height: 100px
    //     width: 100%
    //     background: #E5E5E5

    .bold-text {
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 1px;

        @include mq("max", 768px) {
            font-size: 25px; } }

    .play {
        width: 60px;
        margin: 0 auto;
        background: $primary;
        color: #fff;
        height: 60px;
        vertical-align: top;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

 }        // border: 8px solid $primary

    .info {
        padding: 8em 0;
        max-height: 450px;
        height: 100%;
        background: linear-gradient(224deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5480567227) 0%), url(../images/services.jpg);
        background-size: cover;
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        border-top-left-radius: 50% 30%;
        border-top-right-radius: 50% 30%;
        margin-left: -5%;
        margin-right: -5%;

        @include mq(max, 768px) {
            border-top-left-radius: 50% 15%;
            border-top-right-radius: 50% 15%; } } }

.check-plans {
    padding-top: 7rem;

    .plan-wrap {
        background: #ED8D1C;
        border-radius: 40px;
        text-align: center;
        color: #fff;
        padding-top: 20px;
        box-shadow: 0px 3px 12px rgba(51, 52, 87, 0.1607843137);

        // box-shadow: 0px 16px 26px #00000029
        margin: 15px auto;
        max-width: 350px;

        &.platinum {
            background: #A73030; }

        &.diamond {
            background: #3062A7; }

        &.gold {
            background: #ED8D1C; }

        &.silver {
            background: #767676; }

        &.executive {
            background: #ED4C1C; } }

    .plan-content-wrap {
        background: #fff;
        border-radius: 40px;
        color: #33345B;
        padding: 20px 0;
        font-weight: 600;
        font-size: 16px; }

    .slick-slide {
        // opacity: 0.5
        transition: all 300ms ease;

        // +mq('max',1200px)
 }        //     opacity: 1

    .slick-slide.slick-center {
        opacity: 1;
        transform: scale(1.05); }

    .slick-dotted.slick-slider {
        margin-bottom: 50px; }

    .slick-dots li button:before {
        top: 15px; }

    .slick-dots li button:before {
        opacity: 1;
        content: "";
        background: #e5e5e5;
        height: 4px;
        border-radius: 5px;
        width: 18px; }

    .slick-dots li.slick-active button:before {
        background: $primary; }

    .slick-list:after {
        content: "";
        position: absolute;
        width: 0%;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 1;
        background: #fff;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.6979166667) 9%, rgba(255, 255, 255, 0.4486169468) 90%, rgba(255, 255, 255, 0) 100%);

        @include mq("max", 1500px) {
            width: 35%; }


        @include mq("max", 1200px) {
            width: 0%; } }

    .slick-list:before {
        content: "";
        background: #fff;
        position: absolute;
        width: 0%;
        right: 0;
        height: 100%;
        z-index: 1;
        background: #fff;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.6979166667) 9%, rgba(255, 255, 255, 0.4486169468) 90%, rgba(255, 255, 255, 0) 100%);

        @include mq("max", 1500px) {
            width: 35%; }


        @include mq("max", 1200px) {
            width: 0%; } } }

.contact {
    background: #4A82FB;

    .form-header {
        font-weight: 700;
        color: $dark-grey;
        margin-bottom: 1em; }

    .sub-text {
        font-weight: 300;
        letter-spacing: 3px;
        font-size: 18px;
        text-transform: uppercase; }

    .header {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: 10px; }

    .card {
        padding: 5em;
        display: flex;
        flex-direction: row;

        @include mq(max, 768px) {
            padding: 2em;
            flex-direction: column; } }

    .contact-img {
        width: 100%;

        @include mq(max, 991px) {
            width: 40%;
            margin-bottom: 15px; }


        @include mq(max, 768px) {
            width: 90%; } }

    @include mq(max, 768px) {
        form {
            padding: 0 !important;
            margin-top: 2em;

            h4 {
                font-size: 2em; } } } }

.testimonials {
    background: #E5E5E5;
    padding-bottom: 7rem !important;

    .quotation {
        width: 25px; }

    .sub-text {
        letter-spacing: 2px;
        font-size: 18px;
        text-transform: uppercase; }

    .header {
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-top: 10px; }

    .section-details {
        width: 95%;
        margin: 0 auto; }

    .card {
        display: flex !important;
        flex-direction: row;
        box-shadow: 0px 3px 12px rgba(51, 52, 87, 0.1607843137); }

    .slick-dots {
        bottom: -50px; }

    .slick-dotted.slick-slider {
        margin-bottom: 10px; }

    .slick-dots li button:before {
        opacity: 1;
        content: "";
        background: #fff;
        height: 4px;
        border-radius: 5px;
        width: 18px; }

    .slick-dots li.slick-active button:before {
        background: $primary; }

    @include mq(max, 768px) {
        .section-details {
            width: 100% !important; } } }

.gallery {
    .nav-tabs {
        justify-content: center;
        border: 0;

        .nav-item {
            margin: 0 10px; }

        .nav-link {
            padding: 5px 15px !important;
            color: #495056 !important;
            font-weight: 600;

            &:hover {
                border-color: transparent; } }

        .nav-link.active {
            border: 0;
            background: $primary;
            color: #fff !important;
            border-radius: 15px;
            box-shadow: 0px 3px 2px #3C3C3C; } } }

// .star-wrap
//     display: inline-block
//     font-size: 16px
//     color: yellow
//     width: 20%

.Stars {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    margin-left: 10px;

    &::before {
        content: "★★★★★";
        letter-spacing: 3px;
        background: linear-gradient(81deg, #fff 88%, #696969 92%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } }
