@import "global/_variables";
@import "global/_common";
@import "global/_header";
@import "global/_loader";
@import "global/_global";
@import "pages/_landing";
@import "pages/_about";
@import "pages/_contact";
@import "pages/_services";
@import "pages/_location";
@import "pages/_covid-19";
@import "global/_animation";
@import "global/_modal";
@import "global/_counter";
